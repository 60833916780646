
        @use 'sass:math';
        @import '~backline-mixins/src/backline-mixins';
        @import 'settings';
      
.DecoratedTextControl {
  position: relative;
  color: inherit;
}

.DecoratedTextControlDecoration {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  overflow: hidden;

  &:first-child {
    left: 0;
    max-width: spacing(4);
  }

  &:last-child {
    right: 0;
    max-width: spacing(5);
  }

  a,
  button {
    pointer-events: auto;
  }
}

.before {
  input {
    padding-left: spacing(5);
  }
}

.after {
  input {
    padding-right: spacing(6);
  }
}


        @use 'sass:math';
        @import '~backline-mixins/src/backline-mixins';
        @import 'settings';
      
.TextControlAddon {
  background-color: $color-blue;
  color: $color-white;
  font-family: $font-heading;
  font-weight: 600;
  height: spacing(6);
  display: inline-flex;
  min-width: spacing(3.5);
  align-items: center;
  justify-content: center;
}

.prefix {
  border-top-left-radius: $radius-small;
  border-bottom-left-radius: $radius-small;
}

.suffix {
  border-top-right-radius: $radius-small;
  border-bottom-right-radius: $radius-small;
}

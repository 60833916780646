
        @use 'sass:math';
        @import '~backline-mixins/src/backline-mixins';
        @import 'settings';
      
.NumberPlate {
  border-radius: $radius-small;
  overflow: hidden;
  display: inline-flex;
}

.NumberPlateDecoration {
  background-color: $color-numberplate-blue;
  color: $color-white;
  display: flex;
  align-items: center;
  border-top-left-radius: $radius-small;
  border-bottom-left-radius: $radius-small;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1) inset;
}

.NumberPlateContent {
  // Text
  font-family: 'UKNumberPlate', 'Arial Narrow', monospace;
  line-height: 1;
  text-transform: uppercase;
  text-align: center;
  font-weight: 700;
  font-variant-numeric: tabular-nums;
  white-space: nowrap;
  color: $color-coolgrey-l25;
  text-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  @include font-smoothing();
  // Container
  min-width: 8ch;
  padding: spacing(1) + 1 spacing(1.75) spacing(1) - 1;
  background-color: $color-numberplate-yellow;
  border-radius: $radius-small;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1) inset;

  .NumberPlateDecoration + & {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

// 24px (spacing * 3)
.size-s {
  .NumberPlateDecoration {
    padding-left: spacing(0.125);
    padding-right: spacing(0.125);
    > span {
      transform: scale(0.6);
    }
  }
  .NumberPlateContent {
    font-size: spacing(2.5);
    padding: spacing(0.25) spacing(0.75);

    // iOS Hack to combat incorrect text alignment - the font file isn't great
    // If this stops working, use a different @supports test which only passes for iOS Safari
    @supports (-webkit-touch-callout: none) {
      padding: (spacing(0.25) + 1) spacing(0.75) (spacing(0.25) - 1);
    }
  }
}

// 32px (spacing * 4)
.size-m {
  .NumberPlateDecoration {
    padding-left: spacing(0.375);
    padding-right: spacing(0.375);
    > span {
      transform: scale(1);
    }
  }
  .NumberPlateContent {
    font-size: spacing(3);
    padding: spacing(0.5) spacing(1.25);
  }
}

// 48px (spacing * 6)
.size-l {
  .NumberPlateDecoration {
    padding-left: spacing(1);
    padding-right: spacing(1);
    > span {
      transform: scale(1.4);
    }
  }
  .NumberPlateContent {
    font-size: spacing(4);
  }
}

.squared {
  &,
  .NumberPlateDecoration,
  .NumberPlateContent {
    border-radius: 0;
  }
}

.outline {
  box-shadow: 0 0 0 1px $color-white inset, 0 0 0 1px $color-white;
}


        @use 'sass:math';
        @import '~backline-mixins/src/backline-mixins';
        @import 'settings';
      
$InfoSummaryBreakpoint: 400px;

.InfoSummary {
  min-height: spacing(15.5);

  @include mq($InfoSummaryBreakpoint) {
    display: flex;

    // IE 11 fix to flexbox height within min-height
    // https://github.com/philipwalton/flexbugs/issues/231
    &::after {
      content: '';
      display: block;
      min-height: inherit;
      font-size: 0;
    }
  }
}

.InfoSummaryImage,
.InfoSummaryContent {
  position: relative;

  @include mq($InfoSummaryBreakpoint) {
    flex: 1 0 50%;
    max-width: 50%;
    display: flex;
    align-items: center;
  }
}

.InfoSummaryContent {
  @include mq($InfoSummaryBreakpoint) {
    padding-left: spacing(1.5);
  }
}

.InfoSummaryContentInner {
  position: relative;
}

.InfoSummaryImage {
  margin-bottom: spacing(1);

  @include mq($InfoSummaryBreakpoint) {
    padding-right: spacing(1.5);
    margin-bottom: 0;
  }
}

.InfoSummaryImageSmall {
  @include mq($InfoSummaryBreakpoint) {
    display: none;
  }
}

.InfoSummaryImageLarge {
  flex: 1 0 100%;
  height: 100%;
  display: none;
  @include mq($InfoSummaryBreakpoint) {
    display: block;
  }
}

// Addons

.InfoSummaryImageAddon {
  position: absolute;
  top: 0;
  right: 0;
  text-shadow: 0 0 1px $color-white, 0 0 2px $color-white, 0 0 3px $color-white,
    0 0 4px $color-white;

  @include mq($InfoSummaryBreakpoint) {
    top: auto;
    right: spacing(2);
    bottom: 0;
    left: 0;
  }
}

.InfoSummaryContentAddon {
  position: absolute;
  bottom: 0;
  right: 0;
  color: $color-coolgrey-l90;
}

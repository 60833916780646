
        @use 'sass:math';
        @import '~backline-mixins/src/backline-mixins';
        @import 'settings';
      
.BackgroundImage {
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
}

.fillContainer {
  height: 100% !important;
  width: 100% !important;
  padding-bottom: 0 !important;
}

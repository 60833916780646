
        @use 'sass:math';
        @import '~backline-mixins/src/backline-mixins';
        @import 'settings';
      
// .Vehicle {}

.VehicleName {
  font-weight: bold;
}

.VehicleList {
  list-style: none;
}
